import React from "react";
import * as Select from "@radix-ui/react-select";
import classNames from "classnames";
import ChevronDownIcon from "../../icons/ChevronDownIcon";
import ChevronUpIcon from "../../icons/ChevronUpIcon";
import CheckIcon from "../../icons/CheckIcon";

const FilterSelect = ({ name, items, onChange, value, type }) => {
  let placeholder = "Choisir un type";
  if (type === "column") {
    placeholder = "Choisir une colonne";
  }
  if (type === "operator") {
    placeholder = "Choisir un operateur";
  }
  return (
    <Select.Root
      id={name}
      name={name}
      onValueChange={(e) => {
        onChange(e);
      }}
      defaultValue={value !== null ? value : ""}
    >
      <Select.Trigger className=" inline-flex justify-between w-full items-center bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
        <Select.Value placeholder={placeholder} />
        <Select.Icon className="ml-2 flex">
          <ChevronDownIcon />
        </Select.Icon>
      </Select.Trigger>
      <Select.Portal>
        <Select.Content className="overflow-hidden w-full bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500  shadow-xl">
          <Select.ScrollUpButton className="SelectScrollButton flex justify-center">
            <ChevronUpIcon />
          </Select.ScrollUpButton>
          <Select.Viewport className="SelectViewport">
            {items.map((item) => {
              return (
                <SelectItem
                  key={item.value}
                  className="hover:bg-blue-700 hover:text-white flex items-center justify-between w-full"
                  value={item.value}
                >
                  {item.label}
                </SelectItem>
              );
            })}
          </Select.Viewport>
          <Select.ScrollDownButton className="SelectScrollButton flex justify-center">
            <ChevronDownIcon />
          </Select.ScrollDownButton>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  );
};

const SelectItem = React.forwardRef(
  ({ children, className, ...props }, forwardedRef) => {
    return (
      <Select.Item
        className={classNames("SelectItem", className)}
        {...props}
        ref={forwardedRef}
      >
        <Select.ItemText>{children}</Select.ItemText>
        <Select.ItemIndicator className="SelectItemIndicator">
          <CheckIcon />
        </Select.ItemIndicator>
      </Select.Item>
    );
  }
);

SelectItem.displayName = "SelectItem";
export default FilterSelect;
