import React, { useContext, useState } from "react";
// import { SidebarIsOpenContext } from "../contexts/sidebarContext";
import { FiHardDrive } from "react-icons/fi";
import HomeIcon from "./icons/HomeIcon";
import SubscriptionIcon from "./icons/SubscriptionIcon";
import SidebarLink from "./links/SidebarLink";
import { useAuth } from "react-oidc-context";
import Switcher from "./buttons/DarkModeSwitcher";

const Sidebar = () => {
  //   const { isOpen, toggleIsOpen } = useContext(SidebarIsOpenContext);
  const auth = useAuth();
  return (
    <div className="m-2 flex flex-col justify-between w-auto dark:bg-gray-900 dark:bg-opacity-70 rounded-lg ">
      <div className="flex flex-col justify-between h-full">
        <div>
          {/* <div className="w-full flex items-center py-4">
            <div className=" flex mx-auto  justify-center dark:fill-white w-8  h-8 bg-blue-700  rounded-lg p-1">
              <svg
                id="Calque_1"
                data-name="Calque 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 500 500"
              >
                <path d="M213.59,237.87h-20.6v-45.67c.23-26.98,19.86-49.87,46.49-54.2v-11.1c-.09-5.59-4.58-10.11-10.16-10.24-17.06,.01-30.94-13.71-31.13-30.76v-17.56h20.6v17.48c.12,5.59,4.6,10.09,10.19,10.24,17.05-.03,30.94,13.69,31.13,30.74v11.8c25.24,5.47,43.32,27.71,43.52,53.54v44.51h-20.6v-44.51c0-19.17-15.54-34.72-34.72-34.72s-34.72,15.54-34.72,34.72v45.75Z" />
                <path d="M248.8,222.17c-5.69,0-10.31-4.62-10.31-10.31h0v-23.47c-.28-5.69,4.11-10.53,9.79-10.8,5.69-.28,10.53,4.11,10.8,9.79,.02,.34,.02,.67,0,1.01v23.47c0,5.69-4.6,10.3-10.29,10.31Z" />
                <path d="M304.14,267.67c0-.53-.18-1.05-.49-1.48-1.35-5.42-6.83-8.72-12.25-7.38-3.54,.88-6.33,3.59-7.31,7.11-.36,.51-.55,1.11-.54,1.73v13.23c0,19.17-15.54,34.71-34.71,34.71s-34.71-15.54-34.71-34.71v-13.3c-.01-.56-.19-1.1-.52-1.56-1.41-5.41-6.93-8.65-12.34-7.24-3.49,.91-6.24,3.61-7.2,7.09-.36,.5-.55,1.09-.54,1.71v13.38c.15,20.2,11.31,38.71,29.11,48.27,.32,.2,.67,.35,1.53,.77,2.18,1.07,4.43,2.02,6.73,2.82,0,.22,.22,.45,.35,.67l7.29,12.64v32.54c-16.75,5.65-25.74,23.8-20.09,40.55,3.59,10.64,12.49,18.62,23.46,21.03,2.27,.48,4.58,.72,6.9,.72,17.66,.1,32.05-14.14,32.15-31.79,.08-13.81-8.72-26.11-21.82-30.5v-32.44l7.42-12.66c0-.22,.25-.45,.35-.67,2.48-.87,4.9-1.9,7.25-3.07,.35-.17,.67-.32,1.29-.69,17.65-9.6,28.69-28.03,28.83-48.12v-13.33h-.12Zm-41.37,137.66c2.01,7.71-2.61,15.58-10.32,17.58-7.71,2.01-15.58-2.61-17.58-10.32-2.01-7.71,2.61-15.58,10.32-17.58,2.36-.61,4.84-.62,7.2-.02,5.1,1.27,9.09,5.24,10.39,10.34h0Z" />
                <path d="M248.26,33.88c65.12,.1,118.03,52.57,118.7,117.68v76.11c-.16,6.61,4.16,12.49,10.51,14.32,7.53,1.94,15.2-2.59,17.14-10.11,.3-1.15,.45-2.33,.45-3.51v-77.87C393.6,69.49,326.75,4.99,245.74,6.43c-78.92,1.41-142.58,65-144.07,143.92v76.44c-.16,6.62,4.17,12.5,10.53,14.32,7.53,1.94,15.2-2.59,17.14-10.11,.29-1.14,.44-2.31,.45-3.49v-75.97c.69-65.01,53.46-117.42,118.47-117.66Z" />
                <path d="M114.7,257.55c-6.37,1.81-10.71,7.7-10.53,14.32v77.18c1.45,81.01,68.29,145.52,149.31,144.07,78.92-1.41,142.58-65.01,144.07-143.92v-76.46c.15-6.61-4.17-12.5-10.53-14.32-7.53-1.94-15.2,2.59-17.14,10.11-.3,1.15-.45,2.33-.45,3.51v75.92c-2.84,65.49-58.23,116.28-123.72,113.45-61.52-2.67-110.78-51.93-113.45-113.45v-76.81c-.02-7.77-6.33-14.06-14.1-14.04-1.17,0-2.33,.15-3.46,.44Z" />
              </svg>
            </div>
            {isOpen && (
              <svg
                id="a"
                data-name="Calque 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 497.08 161.3"
              >
                <path
                  className="b"
                  d="M0,124.71V0H10.11V54.79c3.04-5.72,7.58-10.5,13.15-13.82,5.75-3.41,12.32-5.16,19-5.06,5.61-.05,11.16,1.13,16.27,3.45,4.99,2.27,9.46,5.55,13.12,9.64,3.77,4.25,6.71,9.17,8.68,14.5,4.16,11.67,4.16,24.41,0,36.08-1.98,5.34-4.95,10.26-8.76,14.5-3.69,4.11-8.19,7.41-13.22,9.7-5.14,2.32-10.71,3.5-16.35,3.46-4.49,.04-8.95-.72-13.17-2.24-4.11-1.48-7.89-3.74-11.13-6.66-3.26-2.93-5.94-6.43-7.92-10.34l-.68,16.69-9.1,.02Zm41.47-6.71c4.39,.05,8.74-.88,12.73-2.7,3.84-1.78,7.25-4.37,10-7.59,2.89-3.41,5.12-7.32,6.58-11.55,3.15-9.47,3.15-19.7,0-29.17-1.46-4.22-3.69-8.13-6.58-11.54-2.75-3.22-6.16-5.81-10-7.59-12.2-5.7-26.69-2.6-35.49,7.59-2.89,3.41-5.12,7.32-6.57,11.55-3.15,9.47-3.15,19.7,0,29.17,1.45,4.23,3.68,8.14,6.57,11.55,5.68,6.64,14.02,10.41,22.76,10.28Z"
                />
                <path
                  className="b"
                  d="M96.75,161.3c-2.02,.01-4.03-.21-6-.68-1.99-.51-3.89-1.3-5.65-2.36l3.54-8.26c2.41,1.38,5.15,2.07,7.93,2,3.47,0,6.83-1.25,9.44-3.54,2.81-2.36,5.22-6.03,7.25-11l5.41-13.46L82.59,38.35h11.13l30.18,74.35,27.14-74.35h10.79l-39.62,101.7c-2.81,7.33-6.33,12.7-10.54,16.1-4.21,3.43-9.49,5.26-14.92,5.15Z"
                />
                <path
                  className="b"
                  d="M204.64,124.71L159.12,5h27.48l32.2,91.88L250.8,5h26.55l-45.52,119.7h-27.19Z"
                />
                <path
                  className="b"
                  d="M311.12,127.24c-6.27,.07-12.49-1.11-18.3-3.46-5.43-2.22-10.34-5.55-14.41-9.78-4.12-4.32-7.33-9.42-9.44-15-2.3-6.07-3.44-12.51-3.37-19-.06-6.34,1.09-12.63,3.37-18.54,2.13-5.53,5.32-10.59,9.38-14.9,8.21-8.68,19.67-13.53,31.61-13.4,6.09-.08,12.14,1.07,17.78,3.37,5.22,2.17,9.91,5.45,13.75,9.61,3.91,4.32,6.92,9.37,8.86,14.86,2.15,6.18,3.21,12.68,3.12,19.22v5.9h-63.93c1.01,6.63,3.42,11.82,7.25,15.59,3.86,3.79,9.1,5.83,14.5,5.65,3.86,.04,7.65-.95,11-2.86,3.4-2,6.21-4.84,8.17-8.26l21.12,6.76c-1.97,4.99-5.09,9.44-9.1,13-4.14,3.63-8.94,6.45-14.13,8.29-5.53,1.99-11.36,2.99-17.23,2.95Zm-1.18-75.87c-4.86-.12-9.56,1.7-13.07,5.06-3.54,3.37-5.87,7.98-7,13.82h39.28c-.91-5.84-3.08-10.45-6.49-13.82-3.38-3.35-7.97-5.17-12.72-5.06Z"
                />
                <path
                  className="b"
                  d="M401.46,127.24c-5.53,.15-11.01-1.1-15.93-3.63-4.42-2.38-8-6.06-10.28-10.53-2.42-4.61-3.63-10.23-3.63-16.86V54.74h-15.17v-19.05h15.17V5.34h23.61v30.35h24.27v19.05h-24.27v38.61c0,4.27,.9,7.55,2.69,9.86s4.55,3.46,8.27,3.46c2.08-.02,4.14-.31,6.15-.84,2.16-.55,4.28-1.29,6.32-2.2l4.22,17.71c-6.45,3.92-13.87,5.97-21.42,5.9h0Z"
                />
                <path
                  className="b"
                  d="M461.01,127.24c-5.84,.1-11.65-.92-17.11-3-4.91-1.88-9.35-4.81-13-8.6-3.53-3.68-6.06-8.21-7.34-13.15l21.25-4.55c1.03,3.36,3.17,6.28,6.07,8.27,3.09,2.06,6.74,3.11,10.45,3,3.33,.13,6.62-.75,9.44-2.53,2.36-1.5,3.76-4.12,3.71-6.91,0-2.47-1.23-4.79-3.29-6.16-2.19-1.63-5.82-2.89-10.87-3.79l-4.72-.84c-9.67-1.69-17-4.84-22-9.45-4.94-4.47-7.68-10.87-7.49-17.53-.09-5.3,1.44-10.51,4.39-14.92,3.05-4.43,7.24-7.96,12.13-10.2,5.61-2.57,11.72-3.83,17.88-3.71,5.56-.07,11.09,.93,16.27,2.95,4.66,1.81,8.84,4.67,12.22,8.35,3.31,3.69,5.63,8.17,6.74,13l-21.07,4.38c-.97-3.14-2.88-5.91-5.48-7.92-2.54-1.94-5.65-2.97-8.84-2.93-3.07-.12-6.09,.77-8.6,2.53-2.15,1.45-3.43,3.88-3.4,6.47,.04,2.33,1.29,4.47,3.29,5.65,2.19,1.51,5.93,2.71,11.21,3.62l4.72,.84c9.55,1.69,16.85,4.87,21.91,9.53,5.01,4.54,7.78,11.04,7.59,17.79,.06,4.15-.83,8.25-2.61,12-1.79,3.65-4.35,6.87-7.5,9.44-3.4,2.77-7.29,4.89-11.47,6.24-4.68,1.48-9.57,2.2-14.48,2.13Z"
                />
              </svg>
            )}
          </div> */}
          <SidebarLink
            label={"Dashboard"}
            icon={<HomeIcon />}
            to={"/"}
            // isOpen={isOpen}
          />
          <SidebarLink
            label={"Souscriptions"}
            icon={<SubscriptionIcon />}
            to={"/subscriptions"}
            // isOpen={isOpen}
          />
          <SidebarLink
            label={"Souscriptions"}
            icon={<SubscriptionIcon />}
            to={"/subscriptions2"}
            isBeta={true}
            // isOpen={isOpen}
          />
          {/* <SidebarLink
            label={"Files"}
            icon={<FiHardDrive />}
            to={"/files"}
            // isOpen={isOpen}
          /> */}
        </div>
        <div>
          <div className="flex flex-col ml-2 gap-y-2">
            <Switcher />
            <button
              className="flex justify-center items-center h-8 w-8 bg-white dark:bg-gray-900 rounded-lg shadow-lg   text-red-500 "
              onClick={() => auth.signoutSilent()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-power"
                width={16}
                height={16}
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M7 6a7.75 7.75 0 1 0 10 0" />
                <line x1={12} y1={4} x2={12} y2={12} />
              </svg>
            </button>
          </div>
          <div className="flex items-center p-2 rounded-r-lg text-gray-800 dark:text-gray-300">
            {/* <button
              className="flex justify-center items-center h-8 w-8 bg-white dark:bg-gray-900 rounded-lg shadow-lg"
              //   onClick={() => toggleIsOpen()}
            >
              {isOpen ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-layout-sidebar-right-expand"
                  width={16}
                  height={16}
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <rect x={4} y={4} width={16} height={16} rx={2} />
                  <path d="M15 4v16" />
                  <path d="M10 10l-2 2l2 2" />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-layout-sidebar-right-collapse"
                  width={16}
                  height={16}
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <rect x={4} y={4} width={16} height={16} rx={2} />
                  <path d="M15 4v16" />
                  <path d="M9 10l2 2l-2 2" />
                </svg>
              )}
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
