import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import reportWebVitals from "./reportWebVitals";
import HomePage from "./pages";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import { AuthProvider } from "react-oidc-context";
import { WebStorageStateStore } from "oidc-client-ts";
import SubscriptionsPage from "./pages/subscriptions";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import TestPage from "./pages/test";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import SubscriptionsPage2 from "./pages/subscriptions2";

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },

  {
    path: "/subscriptions",
    element: <SubscriptionsPage />,
  },
  {
    path: "/subscriptions2",
    element: <SubscriptionsPage2 />,
  },
  {
    path: "/test",
    element: <TestPage />,
  },
  { path: "*", element: <Navigate to={"/"} /> },
]);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
const oidcConfig = {
  authority:
    process.env.REACT_APP_KC_URL + "realms/" + process.env.REACT_APP_KC_REALM,
  client_id: process.env.REACT_APP_KC_CLIENT_ID,
  // redirect_uri: process.env.REACT_APP_CLIENT_URL,
  redirect_uri: process.env.REACT_APP_CLIENT_URL,
  automaticSilentRenew: true,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  scope: "openid email profile roles",
};

const onSigninCallback = (user) => {
  window.history.replaceState({}, document.title, window.location.pathname);
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthProvider {...oidcConfig} onSigninCallback={() => onSigninCallback()}>
      <QueryClientProvider client={queryClient}>
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
        <RouterProvider router={router} />
      </QueryClientProvider>
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
