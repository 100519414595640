import React, { useEffect, useState } from "react";
import FilterSelect from "./FilterSelect";
import FilterValue from "./FilterValue";
import { HiPlus, HiMinus } from "react-icons/hi";
const FilterLine = ({
  onChange,
  id,
  columnValue,
  operatorValue,
  dataValue,
  addParam,
  removeParam,
  filter,
}) => {
  const [column, setColumn] = useState(columnValue ? columnValue : "");
  const [operator, setOperator] = useState(operatorValue ? operatorValue : "");
  const [value, setValue] = useState(dataValue ? dataValue : "");

  const columns = [
    {
      id: 1,
      value: "firstname",
      label: "Prénom",
    },
    {
      id: 2,
      value: "lastname",
      label: "Nom",
    },
    {
      id: 3,
      value: "omv",
      label: "Numéro ordre",
    },
    {
      id: 4,
      value: "company",
      label: "Société",
    },
    {
      id: 5,
      value: "address",
      label: "Rue + n°",
    },
    {
      id: 6,
      value: "country",
      label: "Pays",
    },
    {
      id: 7,
      value: "city",
      label: "Ville",
    },
    {
      id: 8,
      value: "zipcode",
      label: "Code postal",
    },
    {
      id: 9,
      value: "phone",
      label: "Téléphone",
    },
    {
      id: 10,
      value: "email",
      label: "Email",
    },
    {
      id: 11,
      value: "email2",
      label: "Email secondaire",
    },
    {
      id: 12,
      value: "sexe",
      label: "Sexe",
    },
    {
      id: 13,
      value: "vat",
      label: "TVA",
    },
    {
      id: 14,
      value: "deposit",
      label: "Dépôt",
    },
    {
      id: 15,
      value: "server_users",
      label: "Utilisateurs serveur",
    },
    {
      id: 16,
      value: "migrate_from",
      label: "Migration de",
    },
    {
      id: 17,
      value: "price",
      label: "Prix",
    },
    {
      id: 18,
      value: "is_paid",
      label: "Payé",
    },
    {
      id: 19,
      value: "is_valid",
      label: "Validé",
    },
    {
      id: 20,
      value: "username",
      label: "Nom d'utilisateur",
    },
    {
      id: 21,
      value: "keycloak_id",
      label: "ID Keycloak",
    },
    {
      id: 22,
      value: "discourse_id",
      label: "ID Discourse",
    },
    {
      id: 23,
      value: "license_first_granted_at",
      label: "Première licence",
    },
    {
      id: 24,
      value: "license_last_granted_at",
      label: "Dernière licence",
    },
    {
      id: 25,
      value: "last_payment_at",
      label: "Dernier paiement",
    },
    {
      id: 26,
      value: "is_active",
      label: "Actif",
    },
    {
      id: 27,
      value: "created_at",
      label: "Créé le",
    },
    {
      id: 28,
      value: "updated_at",
      label: "Mis à jour le",
    },
    {
      id: 29,
      value: "mollie_id",
      label: "ID Mollie",
    },
    {
      id: 30,
      value: "payment_status",
      label: "Statut du paiement",
    },
    {
      id: 31,
      value: "mailing_date",
      label: "Date d'envoi",
    },
    {
      id: 32,
      value: "is_vat_validated",
      label: "TVA validée",
    },
    {
      id: 33,
      value: "title",
      label: "Titre",
    },
    {
      id: 34,
      value: "politesse",
      label: "Politesse",
    },
    {
      id: 35,
      value: "shareholding_type",
      label: "Type d'actionnariat",
    },
    {
      id: 36,
      value: "shareholding_started_at",
      label: "Début d'actionnariat",
    },
    {
      id: 37,
      value: "shareholding_ended_at",
      label: "Fin d'actionnariat",
    },
    {
      id: 38,
      value: "subscription_started_at",
      label: "Début d'abonnement",
    },
    {
      id: 39,
      value: "subscription_ended_at",
      label: "Fin d'abonnement",
    },
    {
      id: 40,
      value: "leaving_reason",
      label: "Raison de départ",
    },
    {
      id: 41,
      value: "operating_system",
      label: "Système d'exploitation",
    },
    {
      id: 42,
      value: "last_pegase_version",
      label: "Dernière version Pégase",
    },
    {
      id: 43,
      value: "last_pegase_updated_at",
      label: "Dernière mise à jour Pégase",
    },
    {
      id: 44,
      value: "formation_base_invitation_date",
      label: "Invitation formation base",
    },
    {
      id: 45,
      value: "formation_base_participation_date",
      label: "Participation formation base",
    },
    {
      id: 46,
      value: "formation_base_invitation_last_date",
      label: "Dernière invitation formation base",
    },
    {
      id: 47,
      value: "formation_perf_invitation_date",
      label: "Invitation formation perf",
    },
    {
      id: 48,
      value: "formation_perf_participation_date",
      label: "Participation formation perf",
    },
    {
      id: 49,
      value: "formation_perf_invitation_last_date",
      label: "Dernière invitation formation perf",
    },
    {
      id: 50,
      value: "ref_pegase",
      label: "Réf Pégase",
    },
    {
      id: 51,
      value: "calendar_service",
      label: "Service calendrier",
    },
    {
      id: 52,
      value: "formation_compta_invitation_date",
      label: "Invitation formation compta",
    },
    {
      id: 53,
      value: "formation_compta_participation_date",
      label: "Participation formation compta",
    },
    {
      id: 54,
      value: "formation_compta_invitation_last_date",
      label: "Dernière invitation formation compta",
    },
  ];

  const operators = [
    {
      id: 1,
      value: "$eq",
      label: "égal",
    },
    {
      id: 2,
      value: "$eqc",
      label: "égal (case-sensitive)",
    },
    {
      id: 3,
      value: "$ne",
      label: "est différent de",
    },
    {
      id: 4,
      value: "$contains",
      label: "contient",
    },
    {
      id: 5,
      value: "$containsc",
      label: "contient (case-sensitive)",
    },
    {
      id: 6,
      value: "$notContains",
      label: "ne contient pas",
    },
    {
      id: 7,
      value: "$notContainsc",
      label: "ne contient pas (case-sensitive)",
    },
    // {
    //   id: 8,
    //   value: "$gt",
    //   label: "plus grand que",
    // },
    // {
    //   id: 9,
    //   value: "$gte",
    //   label: "plus grand ou égal à",
    // },
    // {
    //   id: 10,
    //   value: "$lt",
    //   label: "moins que",
    // },
    // {
    //   id: 11,
    //   value: "$lte",
    //   label: "moins ou égal à",
    // },

    {
      id: 12,
      value: "$null",
      label: "est null",
    },
    {
      id: 13,
      value: "$notNull",
      label: "n'est pas null",
    },
    {
      id: 14,
      value: "$startsWith",
      label: "commence par",
    },
    {
      id: 15,
      value: "$startsWithc",
      label: "commence par (case-sensitive)",
    },
    {
      id: 16,
      value: "$endsWith",
      label: "finit par",
    },
    {
      id: 17,
      value: "$endsWithc",
      label: "finit par (case-sensitive)",
    },
  ];

  const onLineChange = () => {
    onChange(id, column, operator, value);
  };
  useEffect(() => {
    onChange(id, column, operator, value);
  }, [column, operator, value]);

  return (
    <div className="flex gap-x-1 px-5 py-1">
      <FilterSelect
        items={columns}
        value={columnValue}
        type="column"
        onChange={(e) => {
          if (e !== null && e !== "") {
            setColumn(e);
          }
        }}
      />
      <FilterSelect
        items={operators}
        value={operatorValue}
        type="operator"
        onChange={(e) => {
          if (e !== null && e !== "") {
            setOperator(e);
          }
        }}
      />
      <input
        type="text"
        defaultValue={dataValue}
        placeholder="Entrez une valeur"
        onChange={(e) => {
          console.log(e.target.value);

          if (e.target.value !== "") {
            setValue(e.target.value);
          }
        }}
        className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
      />
      <button
        onClick={() => removeParam()}
        disabled={id === 0}
        className={` inline-flex max-h-[40px] items-center py-2.5 px-2.5 font-medium text-gray-900 focus:outline-none  bg-white  rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 transition-all disabled:opacity-20 disabled:cursor-not-allowed`}
      >
        <HiMinus size={18}></HiMinus>
      </button>
      <button
        onClick={() => addParam(id)}
        className={` inline-flex max-h-[40px] items-center py-2.5 px-2.5 font-medium text-gray-900 focus:outline-none  bg-white  rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 transition-all`}
      >
        <HiPlus size={18}></HiPlus>
      </button>
    </div>
  );
};

export default FilterLine;
