import React, { useEffect, useReducer, useRef, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
} from "@tanstack/react-table";
import { rankItem } from "@tanstack/match-sorter-utils";
import { useForm } from "react-hook-form";
import * as Popover from "@radix-ui/react-popover";
import { CSVLink } from "react-csv";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import ChevronUpIcon from "../components/icons/ChevronUpIcon";
import ChevronDownIcon from "../components/icons/ChevronDownIcon";
import GripVerticalIcon from "../components/icons/GripVerticalIcon";
import TableActionButton from "../components/buttons/TableActionButton";
import TableDeleteButton from "../components/buttons/TableDeleteButton";
import EyeIcon from "../components/icons/EyeIcon";
import EditIcon from "../components/icons/EditIcon";
import TrashIcon from "../components/icons/TrashIcon";
import FileDownloadIcon from "../components/icons/FileDownloadIcon";
import LockIcon from "../components/icons/LockIcon";
import UnlockIcon from "../components/icons/UnlockIcon";
import EditSubscriptionForm from "../components/forms/subscriptions/editSubscription";
import ArrowsHorizontalIcon from "../components/icons/ArrowsHorizontalIcon";
// import { useKeycloak } from "@react-keycloak/web";
import { toast } from "react-hot-toast";
import { PuffLoader } from "react-spinners";
import AddSubscriptionForm from "../components/forms/subscriptions/addSubscription";
import ReactModal from "react-modal";
import { TbLetterA } from "react-icons/tb";
import { HiRefresh } from "react-icons/hi";
import { formatDateDDMMYYYY, formatDateNoTime } from "../utils/formatDate";
import AdminLayout from "../components/layout/AdminLayout";
import { useAuth } from "react-oidc-context";
import KeyIcon from "../components/icons/KeyIcon";
import TableAccountButton from "../components/buttons/TableAccountButton";
import DropDownDots from "../components/buttons/DropDownDots";
import * as Label from "@radix-ui/react-label";
import * as AlertDialog from "@radix-ui/react-alert-dialog";

import qs from "qs";
import { useFormik } from "formik";
import TableFilter from "../components/table/TableFilter";
import { PlusIcon } from "@radix-ui/react-icons";
import { stringSimilarity } from "string-similarity-js";
import diacriticless from "diacriticless";
import { DarkModeSwitch } from "react-toggle-dark-mode";
import Switcher from "../components/buttons/DarkModeSwitcher";
import { HiOutlineFunnel, HiPlus } from "react-icons/hi2";
import FilterLine from "../components/forms/subscriptions/FilterForm";
import FilterForm from "../components/forms/subscriptions/FilterForm";

// TableHelpers
// --Search:
const fuzzyFilter = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // Store the itemRank info
  addMeta({
    itemRank,
  });
  // Return if the item should be filtered in/out
  return itemRank.passed;
};
const allowedExtensions = ["csv"];
const selectPaymentStatusOptions = [
  { value: null, label: "Aucun" },
  { value: "cancelled", label: "Annulé" },
  { value: "expired", label: "Expiré" },
  { value: "paid", label: "Payé" },
];
const selectMigrateFromOptions = [
  { value: "none", label: "Aucun" },
  { value: "fuga", label: "Fuga" },
  { value: "vetesys", label: "Vetesys" },
  { value: "cinnabear", label: "Cinnabear" },
];

const reorderColumn = (draggedColumnId, targetColumnId, columnOrder) => {
  columnOrder.splice(
    columnOrder.indexOf(targetColumnId),
    0,
    columnOrder.splice(columnOrder.indexOf(draggedColumnId), 1)[0]
  );
  return [...columnOrder];
};

// Filter firstname, lastnames

const filterByNames = (row, columnId, filterValue, addMeta) => {
  // const minDate = filterValue[0]
  //   ? new Date(filterValue[0]).setHours(0, 0, 0, 0)
  //   : new Date(-8640000000000000);
  // const maxDate = filterValue[1]
  //   ? new Date(filterValue[1]).setHours(23, 59, 59, 999)
  //   : new Date(8640000000000000);
  // const dateToCheck = new Date(row.getValue(columnId));
  // return dateToCheck >= minDate && dateToCheck <= maxDate;
  let firstName = row.getValue("firstname");
  let lastName = row.getValue("lastname");
  let company = row.getValue("company");

  if (firstName === null) {
    firstName = "";
  }
  if (lastName === null) {
    lastName = "";
  }
  if (company === null) {
    company = "";
  }
  const dataString = lastName + " " + firstName + " " + company;
  const queryString = filterValue.toLowerCase();

  return dataString.toLowerCase().includes(queryString);
  // if (levenshteinDistance <= 2) {
  //   return true;
  // } else {
  //   return false;
  // }
};
// Filter by date Function

const filterByDate = (row, columnId, filterValue, addMeta) => {
  const minDate = filterValue[0]
    ? new Date(filterValue[0]).setHours(0, 0, 0, 0)
    : new Date(-8640000000000000);
  const maxDate = filterValue[1]
    ? new Date(filterValue[1]).setHours(23, 59, 59, 999)
    : new Date(8640000000000000);
  const dateToCheck = new Date(row.getValue(columnId));
  return dateToCheck >= minDate && dateToCheck <= maxDate;
};

// --DnD:
const DraggableColumnHeader = ({ header, table, columnResizeMode }) => {
  const { getState, setColumnOrder } = table;
  const { columnOrder } = getState();
  const { column } = header;

  const [, dropRef] = useDrop({
    accept: "column",
    drop: (draggedColumn) => {
      const newColumnOrder = reorderColumn(
        draggedColumn.id,
        column.id,
        columnOrder
      );
      setColumnOrder(newColumnOrder);
    },
  });

  const [{ isDragging }, dragRef, previewRef] = useDrag({
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    item: () => column,
    type: "column",
  });

  return (
    <th
      ref={dropRef}
      colSpan={header.colSpan}
      style={{ opacity: isDragging ? 0.5 : 1 }}
      {...{
        key: header.id,
        colSpan: header.colSpan,
        style: {
          width: header.getSize(),
        },
      }}
    >
      <div
        ref={previewRef}
        className="flex items-center py-2 group "
        {...{
          className: header.column.getCanSort()
            ? "flex items-center py-2 group cursor-pointer select-none justify-between"
            : "flex items-center py-2 group justify-between",
          onClick: header.column.getToggleSortingHandler(),
        }}
      >
        <div className="flex">
          {header.isPlaceholder
            ? null
            : flexRender(header.column.columnDef.header, header.getContext())}
          {{
            asc: <ChevronUpIcon />,
            desc: <ChevronDownIcon />,
          }[header.column.getIsSorted()] ?? null}
        </div>
        <div className="flex flex-1 justify-between">
          <div
            ref={dragRef}
            className="opacity-0 group-hover:opacity-100 cursor-move ml-1"
          >
            <GripVerticalIcon />
          </div>
          <div
            {...{
              onMouseDown: header.getResizeHandler(),
              onTouchStart: header.getResizeHandler(),
              className: `resizer ${
                header.column.getIsResizing() ? "isResizing" : ""
              }`,
              style: {
                transform:
                  columnResizeMode === "onEnd" && header.column.getIsResizing()
                    ? `translateX(${
                        table.getState().columnSizingInfo.deltaOffset
                      }px)`
                    : "",
              },
            }}
            className="opacity-0 group-hover:opacity-100 cursor-ew-resize ml-1 mr-2"
          >
            <ArrowsHorizontalIcon />
          </div>
        </div>
      </div>
    </th>
  );
};

function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}) {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(value);
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <input
      {...props}
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  );
}

let showAllSub = false;

const SubscriptionsPage2 = () => {
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [globalFilter, setGlobalFilter] = useState("");
  const [columnFilters, setColumnFilters] = useState([]);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [filters, setFilters] = useState([]);
  const [oldFilters, setOldFilters] = useState([]);

  const [newFilters, setNewFilters] = useState([
    { column: null, operator: null, value: null },
  ]);
  const [globalOperator, setGlobalOperator] = useState("and");
  const [filteredSubscriptions, setFilteredSubscriptions] = useState([]);
  const [dataCSV, setDataCSV] = useState([]);
  const [isBusy, setIsBusy] = useState(false);
  const [tableFontSize, setTableFontSize] = useState(1);
  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
  const [isFetchingAllUsers, setIsFetchingAllUsers] = useState(false);
  const [isActivateAccountAlertOpen, setIsActivateAccountAlertOpen] =
    useState(false);

  const increaseTableFontSize = () => {
    var tenPercent = tableFontSize * 0.1; // Calculate 10% of the value
    var result = tableFontSize + tenPercent; // Add 10% to the value
    setTableFontSize(result);
  };
  const reduceTableFontSize = () => {
    var tenPercent = tableFontSize * 0.1; // Calculate 10% of the value
    var result = tableFontSize - tenPercent; // Add 10% to the value
    setTableFontSize(result);
  };
  // Add new filter param
  const addParam = (index) => {
    const newLine = { column: null, operator: null, value: null };
    const updatedFilters = [...newFilters];
    updatedFilters.splice(index + 1, 0, newLine);
    setNewFilters(updatedFilters);
  };

  const removeParam = (index) => {
    const updatedItems = [...newFilters];
    updatedItems.splice(index, 1);
    setNewFilters(updatedItems);
  };
  const transformFilters = (filters) => {
    if (filters.length === 0 || !filters || filters[0].column === null) {
      return null;
    }
    if (globalOperator === "or") {
      return {
        $or: filters.map((filter) => ({
          [filter.column]: {
            [filter.operator]: filter.value,
          },
        })),
      };
    }
    return {
      $and: filters.map((filter) => ({
        [filter.column]: {
          [filter.operator]: filter.value,
        },
      })),
    };
  };

  const getFiltersCount = () => {
    return filters.length;
  };
  // Column Definitions
  const defaultColumns = [
    { accessorKey: "id", id: "id", enableGlobalFilter: false },
    {
      id: "actions2",
      enableResizing: false,
      enableOrdering: false,
      size: 40,
      cell: (info) => {
        return (
          <DropDownDots
            openForm={openForm}
            info={info}
            deleteMutation={deleteSubscriptionMutation}
            openDeleteForm={openDeleteForm}
            openAccountActivationForm={openAccountActivationForm}
          />
        );
      },
    },
    {
      accessorKey: "lastname",
      id: "lastname",
      width: "auto",
      header: "Nom",
      enableSorting: true,
      filterFn: filterByNames,

      cell: (info) => {
        return (
          <div className="flex flex-col">
            <span>
              <div
                className={`h-2 w-2 rounded-full ${
                  info.row.original.subscription_ended_at
                    ? "bg-red-500"
                    : "bg-green-500"
                } inline-block mr-1`}
              ></div>
              {info.row.original.lastname} {info.row.original.firstname}
            </span>
            <span
              className="italic text-neutral-500"
              style={{ fontSize: `${tableFontSize * 0.9}em` }}
            >
              {info.row.original.company}
            </span>
          </div>
        );
      },
    },
    {
      accessorKey: "firstname",
      id: "firstname",
      header: "Prénom",
      width: "auto",
      cell: (info) => info.getValue(),
    },
    {
      accessorKey: "company",
      id: "company",
      header: "Société",
      cell: (info) => info.getValue(),
    },
    {
      accessorKey: "deposit",
      id: "deposit",
      header: "Dépôt",
      cell: (info) => info.getValue(),
    },
    {
      accessorKey: "omv",
      id: "omv",
      header: "OMV",
      cell: (info) => info.getValue(),
    },
    {
      accessorKey: "vat",
      id: "vat",
      cell: (info) => {
        if (info.row.original.is_vat_validated) {
          return (
            <span className="bg-green-100 text-green-800 text-xs  mr-2 px-2.5 py-0.5 rounded dark:bg-green-200 dark:text-green-900">
              {info.getValue()}
            </span>
          );
        }
        return (
          <span className="bg-red-100 text-red-800 text-xs  mr-2 px-2.5 py-0.5 rounded dark:bg-red-200 dark:text-red-900">
            {info.getValue()}
          </span>
        );
      },
    },
    {
      accessorKey: "address",
      id: "address",
      header: "Adresse",
    },
    {
      accessorKey: "zipcode",
      id: "zipcode",
      header: "CP",
    },
    {
      accessorKey: "city",
      id: "city",
      header: "Ville",
    },
    {
      accessorKey: "country",
      id: "country",
      header: "Pays",
    },

    {
      accessorKey: "email",
      id: "email",
      cell: (info) => (
        <a href={`mailto:${info.getValue()}`}>{info.getValue()}</a>
      ),
    },
    {
      accessorKey: "phone",
      id: "phone",
      header: "Téléphone",
    },
    {
      accessorKey: "price",
      id: "price",
      header: "Prix",
      cell: (info) => {
        if (info.row.original.payment_status === "paid") {
          return (
            <span className="bg-green-100 text-green-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-green-200 dark:text-green-900">
              {info.getValue()}
            </span>
          );
        }
        if (
          info.row.original.payment_status === "canceled" ||
          info.row.original.payment_status === "expired"
        ) {
          return (
            <span className="bg-red-100 text-red-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-red-200 dark:text-red-900">
              {info.getValue()}
            </span>
          );
        }
        return (
          <span className="bg-yellow-100 text-yellow-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-yellow-200 dark:text-yellow-900">
            {info.getValue()}
          </span>
        );
      },
    },
    {
      accessorKey: "server_users",
      id: "server_users",
      header: "Nbre utilisateurs",
      cell: (info) => info.getValue(),
    },
    {
      accessorKey: "migrate_from",
      id: "migrate_from",
      header: "Migration",
      cell: (info) => info.getValue(),
    },
    {
      accessorKey: "shareholding_type",
      id: "shareholding_type",
      header: "Actionnariat",
      cell: (info) => info.getValue(),
    },
    {
      accessorKey: "shareholding_started_at",
      id: "shareholding_started_at",
      header: "Actionnaire depuis le",
      cell: (info) => formatDateNoTime(info.getValue()),
      filterFn: filterByDate,
    },
    {
      accessorKey: "shareholding_ended_at",
      id: "shareholding_ended_at",
      header: "Actionnaire jusqu'au",
      cell: (info) => formatDateNoTime(info.getValue()),
      filterFn: filterByDate,
    },
    {
      accessorKey: "subscription_started_at",
      id: "subscription_started_at",
      header: "Membre depuis le",
      cell: (info) => formatDateNoTime(info.getValue()),
      filterFn: filterByDate,
      enableGlobalFilter: false,
    },
    {
      accessorKey: "subscription_ended_at",
      id: "subscription_ended_at",
      header: "Membre jusqu'au",
      cell: (info) => formatDateNoTime(info.getValue()),
      filterFn: filterByDate,
    },
    { id: "operating_system", accessorKey: "operating_system", header: "OS" },
    {
      id: "last_pegase_version",
      accessorKey: "last_pegase_version",
      header: "Dernière version de Péagse",
      cell: (info) => formatDateNoTime(info.getValue()),

      filterFn: filterByDate,
    },
    {
      id: "last_pegase_updated_at",
      accessorKey: "last_pegase_updated_at",
      header: "Dernière maj de Péagse",
      cell: (info) => formatDateNoTime(info.getValue()),

      filterFn: filterByDate,
    },
    {
      id: "formation_base_invitation_date",
      accessorKey: "formation_base_invitation_date",
      header: "Formation base date invitation",
      cell: (info) => formatDateNoTime(info.getValue()),

      filterFn: filterByDate,
    },
    {
      id: "formation_base_participation_date",
      accessorKey: "formation_base_participation_date",
      header: "Formation base date participation",
      cell: (info) => formatDateNoTime(info.getValue()),

      filterFn: filterByDate,
    },
    {
      id: "formation_base_invitation_last_date",
      accessorKey: "formation_base_invitation_last_date",
      header: "Formation base dern. date invitation",
      cell: (info) => formatDateNoTime(info.getValue()),

      filterFn: filterByDate,
    },
    {
      id: "formation_perf_invitation_date",
      accessorKey: "formation_perf_invitation_date",
      header: "Formation perf date invitation",
      cell: (info) => formatDateNoTime(info.getValue()),
      filterFn: filterByDate,
    },
    {
      id: "formation_perf_participation_date",
      accessorKey: "formation_perf_participation_date",

      header: "Formation perf date participation",
      cell: (info) => formatDateNoTime(info.getValue()),
      filterFn: filterByDate,
    },
    {
      id: "formation_perf_invitation_last_date",
      accessorKey: "formation_perf_invitation_last_date",
      header: "Formation perf dern. date invitation",
      cell: (info) => formatDateNoTime(info.getValue()),
      filterFn: filterByDate,
    },
    {
      accessorKey: "calendar_service",
      id: "calendar_service",
      header: "Agenda",
    },
    {
      accessorKey: "created_at",
      id: "created_at",
      header: "Crée le",
      cell: (info) =>
        new Date(info.getValue()).toLocaleDateString(undefined, {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        }),
    },
    {
      accessorKey: "updated_at",
      id: "updated_at",
      header: "Modifié le",
      cell: (info) =>
        new Date(info.getValue()).toLocaleDateString(undefined, {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        }),
    },

    // {
    //   id: "actions",

    //   className: "text-right",
    //   header: () => <div className="text-right w-full">Actions</div>,
    //   cell: (info) => {
    //     return (
    //       <div className="flex justify-end">
    //         {/* <TableActionButton
    //           onClick={(e) => {
    //             openForm(e, info.row.original, true);
    //           }}
    //           label={<EyeIcon />}
    //         />
    //         <TableActionButton
    //           onClick={(e) => {
    //             openForm(e, info.row.original, false);
    //           }}
    //           label={<EditIcon />}
    //         />
    //         <TableAccountButton user={info.row.original} />

    //         <TableDeleteButton
    //           className="opacity-0 transition-all text-red-500 group-hover:opacity-100 ml-1"
    //           subscription={info.row.original}
    //           deleteAction={deleteSubscriptionMutation}
    //           label={<TrashIcon />}
    //         /> */}
    //       </div>
    //     );
    //   },
    // },
  ];

  const columnsNames = [
    { id: "name", label: "Nom" },
    { id: "lastname", label: "Nom" },

    { id: "address", label: "Adresse" },
    { id: "zipcode", label: "Code Postal" },
    { id: "city", label: "Ville" },
    { id: "country", label: "Pays" },
    { id: "email", label: "Email" },
    { id: "phone", label: "Téléphone" },
    { id: "price", label: "Prix" },
    { id: "server_users", label: "Nbre utilisateurs" },
    { id: "migrate_from", label: "Migration" },
    { id: "company", label: "Société" },
    { id: "omv", label: "OMV" },

    { id: "vat", label: "TVA" },
    { id: "deposit", label: "Depot" },

    { id: "shareholding_type", label: "Actionnariat" },
    { id: "shareholding_started_at", label: "Actionnaire depuis" },
    { id: "shareholding_ended_at", label: "Actionnaire jusqu'au" },
    { id: "subscription_started_at", label: "Membre depuis le" },
    { id: "subscription_ended_at", label: "Membre jusqu'au" },
    { id: "operating_system", label: "OS" },
    { id: "last_pegase_version", label: "Dernière version de Péagse" },
    { id: "last_pegase_updated_at", label: "Dernière maj de Péagse" },
    {
      id: "formation_base_invitation_date",
      label: "Formation base date invitation",
    },
    {
      id: "formation_base_participation_date",
      label: "Formation base date participation",
    },
    {
      id: "formation_base_invitation_last_date",
      label: "Formation base dern. date invitation",
    },
    {
      id: "formation_perf_invitation_date",
      label: "Formation perf date invitation",
    },
    {
      id: "formation_perf_participation_date",
      label: "Formation perf date participation",
    },
    {
      id: "formation_perf_invitation_last_date",
      label: "Formation perf dern. date invitation",
    },
    {
      id: "calendar_service",
      label: "Agenda",
    },
    {
      id: "created_at",
      label: "Créé le ",
    },
    {
      id: "updated_at",
      label: "Modifié le",
    },
  ];

  const getColumnLabel = (id) => {
    const item = columnsNames.find((obj) => {
      return obj.id === id;
    });

    if (!!item) {
      return item.label;
    }

    return id;
  };

  const [columns] = useState(() => [...defaultColumns]);
  const [columnOrder, setColumnOrder] = useState(
    columns.map((column) => column.id) //must start out with populated columnOrder so we can splice
  );
  const [sorting, setSorting] = useState([]);

  const [columnVisibility, setColumnVisibility] = useState({
    id: false,
    name: true,
    firstname: false,
    company: false,
    deposit: true,
    omv: true,
    vat: true,
    address: false,
    zipcode: false,
    city: false,
    country: false,
    email: true,
    phone: true,
    price: false,
    server_users: false,
    migrate_from: false,
    shareholding_type: false,
    shareholding_ended_at: false,
    subscription_started_at: false,
    operating_system: false,
    last_pegase_version: false,
    last_pegase_updated_at: false,
    formation_base_invitation_date: false,
    formation_base_participation_date: false,
    formation_base_invitation_last_date: false,
    formation_perf_invitation_date: false,
    formation_perf_participation_date: false,
    formation_perf_invitation_last_date: false,
    calendar_service: false,
    created_at: false,
    updated_at: false,
  });

  const [columnResizeMode, setColumnResizeMode] = useState("onChange");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isFormLocked, setIsFormLocked] = useState(true);
  const [keycloakUsers, setKeycloakUsers] = useState([]);

  const queryClient = useQueryClient();
  const auth = useAuth();

  useEffect(() => {
    if (auth) {
      refetch();
      console.log("========USEEFFECT AUTH===========");
    }
  }, [auth]);

  const [activeFilters, setActiveFilters] = useState([]);
  const [querySubscriptions, setQuerySubscriptions] = useState({});

  const generateQueryObject = () => {
    // Start with a base query object
    let queryObject = {
      filters: {},
    };
    console.log(
      "querySubscriptions",
      filterForm.values.subscriptionEndedAtIsNull
    );
    // Add a property based on the checkbox state
    if (filterForm.values.subscriptionEndedAtIsNull) {
      queryObject.filters = {
        ...queryObject.filters,
        subscription_ended_at: { $null: true },
      };
    }

    // You can add more conditions and properties based on other state values...

    return qs.stringify(queryObject, { encodeValuesOnly: true });
  };

  const filterForm = useFormik({
    initialValues: {
      subscriptionEndedAtIsNull: true,
    },
  });

  const handleFilterFormChange = (e) => {
    // Your custom action here
    console.log(`Field 'fieldName' changed to: ${e.target.value}`);

    // Call Formik's handleChange to update the form state
    filterForm.handleChange(e);
    refetch();
  };

  // console.log(auth);

  const query = qs.stringify(
    {
      filters: {
        subscription_ended_at: { $null: false },
      },
    },
    {
      encodeValuesOnly: true, // prettify URL
    }
  );

  const stringifiedFilters = () => {
    const newFilters = transformFilters(filters);
    const stringFilters = qs.stringify(
      {
        filters: newFilters,
      },
      {
        encodeValuesOnly: true, // prettify URL
      }
    );
    if (stringFilters === "filters=") {
      return "";
    }
    return stringFilters;
  };

  const fetchUsers = async () => {
    console.log("auth before fetch", auth);
    if (auth.user !== undefined) {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_API_URL +
          `/api/subscriptions/filtered?` +
          stringifiedFilters(),
        {
          headers: { Authorization: `Bearer ${auth.user.access_token}` },
        }
      );
      console.log(response.data);
      return response.data;
    }
  };

  const fetchSubscriptions = async () => {
    const response = await axios.get(
      process.env.REACT_APP_BACKEND_API_URL +
        `/api/subscriptions/filtered${
          !isFetchingAllUsers ? "" : "/affiliated"
        }`,
      {
        headers: { Authorization: `Bearer ${auth.user.access_token}` },
      }
    );
    console.log(response.data);
    setFilteredSubscriptions(response.data);
  };

  const { isLoading, error, data, isFetching, refetch } = useQuery(
    ["subscriptionsData"],
    fetchUsers,
    {
      onSuccess: (e) => {
        setDataCSV(e.data);
      },
      onError: (e) => {
        console.log("error", e);
      },
    }
  );

  useEffect(() => {
    if (filters) {
      refetch();
    }
  }, [filters]);

  useEffect(() => {
    console.log("myState changed:", newFilters);
  }, [newFilters]);

  const applyFilters = () => {
    setIsFilterModalOpen(false);
    setFilters(newFilters);
    setOldFilters(newFilters);
  };

  const cancelFilters = () => {
    setIsFilterModalOpen(false);
    refetch();
  };

  const resetFilters = () => {
    setNewFilters([{ column: null, operator: null, value: null }]);
    setFilters([]);
    setIsFilterModalOpen(false);
  };

  const table = useReactTable({
    data: data ? data.data : [],
    columns,
    columnResizeMode,
    state: {
      columnVisibility,
      columnOrder,
      sorting,
      columnFilters,
      globalFilter,
    },
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    onColumnVisibilityChange: setColumnVisibility,
    onColumnOrderChange: setColumnOrder,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),

    // debugTable: true,
    // debugHeaders: true,
    // debugColumns: true,
  });

  const fetchActiveUsers = async () => {
    await queryClient.refetchQueries({
      queryKey: ["subscription_ended_at"],
      type: "active",
    });
  };

  const getCSVData = () => {
    return data.data;
  };
  // Form

  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      migrate_from:
        selectedSubscription !== null ? selectedSubscription.migrate_from : "",
    },
  });
  const onSubmit = async (data) => {
    console.log(data);
    // editSubscriptionMutation.mutate(data)
  };

  const formatDate = (d) => {
    let date = new Date(d);
    return date.toISOString();
  };

  const closeAddForm = () => {
    document.body.style.overflow = "auto";
    setIsAddModalOpen(false);
  };

  const openAddForm = (e, sub, isLocked = true) => {
    e.stopPropagation();
    setIsAddModalOpen(true);
    document.body.style.overflow = "hidden";
  };
  const closeForm = () => {
    setSelectedSubscription(null);
    setIsFormLocked(true);
    document.body.style.overflow = "auto";
    setIsModalOpen(false);
  };

  const openForm = (e, sub, isLocked = true) => {
    e.stopPropagation();
    console.log(sub);
    setSelectedSubscription(sub);
    setIsModalOpen(true);
    document.body.style.overflow = "hidden";
    setIsFormLocked(isLocked);
  };

  const openDeleteForm = (sub) => {
    setSelectedSubscription(sub);
    setIsDeleteAlertOpen(true);
  };
  const openAccountActivationForm = (sub) => {
    setSelectedSubscription(sub);
    setIsActivateAccountAlertOpen(true);
  };

  const editSubscriptionMutation = useMutation(
    (body) => {
      return axios.put(
        process.env.REACT_APP_BACKEND_API_URL +
          "/api/admin/subscriptions/" +
          selectedSubscription.id,
        {
          ...body,
        },
        { headers: { Authorization: `Bearer ${auth.user.access_token}` } }
      );
    },
    {
      onMutate: async (body) => {
        console.log("mutate edit body", body);

        await queryClient.cancelQueries("subscriptionsData");

        const previousSubscriptions = queryClient.getQueriesData([
          "subscriptionsData",
        ]);

        if (previousSubscriptions) {
          queryClient.setQueryData(["subscriptionsData"], {
            ...previousSubscriptions,
            data: [...previousSubscriptions, body],
          });
        }
        toast.success("Souscription mise à jour.");
        closeForm();

        return { previousSubscriptions };
      },

      onError: (err, variables, context) => {
        if (context.pre) {
          queryClient.setQueryData(
            ["subscriptionsData"],
            context.previousSubscriptions
          );
        }

        toast.error("Une erreur est survenue, veuillez réessayer");
      },
      onSettled: () => {
        queryClient.invalidateQueries(["subscriptionsData"]);
      },
    }
  );

  const addSubscriptionMutation = useMutation(
    (body) => {
      return axios.post(
        process.env.REACT_APP_BACKEND_API_URL + "/api/admin/subscriptions/",
        {
          ...body,
        },
        { headers: { Authorization: `Bearer ${auth.user.access_token}` } }
      );
    },
    {
      onMutate: async (body) => {
        await queryClient.cancelQueries("subscriptionsData");

        const previousSubscriptions = queryClient.getQueriesData([
          "subscriptionsData",
        ]);

        if (previousSubscriptions) {
          queryClient.setQueryData(["subscriptionsData"], {
            ...previousSubscriptions,
            data: [previousSubscriptions, body],
          });
        }
        toast.success("Souscription mise à jour.");
        closeAddForm();

        return { previousSubscriptions };
      },

      onError: (err, variables, context) => {
        if (context.pre) {
          queryClient.setQueryData(
            ["subscriptionsData"],
            context.previousSubscriptions
          );
        }

        toast.error("Une erreur est survenue, veuillez réessayer");
      },
      onSettled: () => {
        queryClient.invalidateQueries(["subscriptionsData"]);
      },
    }
  );

  const deleteSubscriptionMutation = useMutation(
    (body) => {
      return axios.delete(
        process.env.REACT_APP_BACKEND_API_URL +
          "/api/admin/subscriptions/" +
          body,
        { headers: { Authorization: `Bearer ${auth.user.access_token}` } }
      );
    },
    {
      onMutate: async (body) => {
        await queryClient.cancelQueries("subscriptionsData");

        const previousSubscriptions = queryClient.getQueriesData([
          "subscriptionsData",
        ]);

        if (previousSubscriptions) {
          queryClient.setQueryData(["subscriptionsData"], {
            ...previousSubscriptions,
            data: [body],
          });
        }
        setSelectedSubscription(null);
        setIsDeleteAlertOpen(false);
        toast.success("Souscription supprimée");

        return { previousSubscriptions };
      },

      onError: (err, variables, context) => {
        if (context.pre) {
          queryClient.setQueryData(
            ["subscriptionsData"],
            context.previousSubscriptions
          );
        }
        toast.error("Une erreur est survenue, veuillez réessayer");
      },
      onSettled: () => {
        queryClient.invalidateQueries(["subscriptionsData"]);
      },
    }
  );

  const activateAccountMutation = useMutation(
    (body) => {
      console.log("body", body);
      return axios.post(
        process.env.REACT_APP_BACKEND_API_URL +
          "/api/admin/account/" +
          selectedSubscription.id +
          "/activate",
        {
          password: body,
        },
        { headers: { Authorization: `Bearer ${auth.user.access_token}` } }
      );
    },
    {
      onMutate: async (body) => {
        await queryClient.cancelQueries("subscriptionsData");

        const previousSubscriptions = queryClient.getQueriesData([
          "subscriptionsData",
        ]);

        if (previousSubscriptions) {
          queryClient.setQueryData(["subscriptionsData"], {
            ...previousSubscriptions,
            data: [...previousSubscriptions, body],
          });
        }
        toast.success("Souscription mise à jour.");
        closeForm();

        return { previousSubscriptions };
      },

      onError: (err, variables, context) => {
        if (context.pre) {
          queryClient.setQueryData(
            ["subscriptionsData"],
            context.previousSubscriptions
          );
        }

        toast.error("Une erreur est survenue, veuillez réessayer");
      },
      onSettled: () => {
        queryClient.invalidateQueries(["subscriptionsData"]);
      },
    }
  );
  const desactivateAccountMutation = useMutation(
    (body) => {
      return axios.get(
        process.env.REACT_APP_BACKEND_API_URL +
          "/api/admin/account/" +
          selectedSubscription.id +
          "/desactivate",
        { headers: { Authorization: `Bearer ${auth.user.access_token}` } }
      );
    },
    {
      onMutate: async (body) => {
        console.log("mutate body", body);
        await queryClient.cancelQueries("subscriptionsData");

        const previousSubscriptions = queryClient.getQueriesData([
          "subscriptionsData",
        ]);

        if (previousSubscriptions) {
          queryClient.setQueryData(["subscriptionsData"], {
            ...previousSubscriptions,
            data: [...previousSubscriptions],
          });
        }
        toast.success("Souscription mise à jour.");
        closeForm();

        return { previousSubscriptions };
      },
      onSuccess: () => {
        console.log("desactive successful");
      },

      onError: (err, variables, context) => {
        if (context.pre) {
          queryClient.setQueryData(
            ["subscriptionsData"],
            context.previousSubscriptions
          );
        }

        toast.error("Une erreur est survenue, veuillez réessayer");
      },
      onSettled: () => {
        queryClient.invalidateQueries(["subscriptionsData"]);
      },
    }
  );
  const reactivateAccountMutation = useMutation(
    (body) => {
      return axios.get(
        process.env.REACT_APP_BACKEND_API_URL +
          "/api/admin/account/" +
          selectedSubscription.id +
          "/reactivate",
        { headers: { Authorization: `Bearer ${auth.user.access_token}` } }
      );
    },
    {
      onMutate: async (body) => {
        await queryClient.cancelQueries("subscriptionsData");

        const previousSubscriptions = queryClient.getQueriesData([
          "subscriptionsData",
        ]);

        if (previousSubscriptions) {
          queryClient.setQueryData(["subscriptionsData"], {
            ...previousSubscriptions,
            data: [...previousSubscriptions],
          });
        }
        toast.success("Compte réactivé.");
        closeForm();

        return { previousSubscriptions };
      },

      onError: (err, variables, context) => {
        if (context.pre) {
          queryClient.setQueryData(
            ["subscriptionsData"],
            context.previousSubscriptions
          );
        }

        toast.error("Une erreur est survenue, veuillez réessayer");
      },
      onSettled: () => {
        queryClient.invalidateQueries(["subscriptionsData"]);
      },
    }
  );

  const handleChangeGlobalFilter = (e) => {
    setGlobalFilter(e);
  };

  const changeVisibility = (e) => {
    const columnName = e.target.value;
    // window.localStorage.setItem(
    //   "admByVetsColumbVisibility",
    //   JSON.stringify(columnVisibility)
    // )

    // setColumnVisibility(prevVisibility => ({
    //   ...prevVisibility,
    //   [columnName]: !e.target.checked, // Toggle the value of the property
    // }))

    // console.log(columnName, e.target.checked)
    // console.log("changeVisibility")

    window.localStorage.setItem(
      "admByVetsColumbVisibility",
      JSON.stringify({ ...columnVisibility, [columnName]: !e.target.checked })
    );
  };

  useEffect(() => {
    if (table.getState().columnFilters[0]?.id === "id") {
      if (table.getState().sorting[0]?.id !== "id") {
        table.setSorting([{ id: "id", desc: false }]);
      }
    }
  }, [table.getState().columnFilters[0]?.id]);

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    setColumnVisibility((prevColumnVisibility) => ({
      ...prevColumnVisibility,
      [value]: checked,
    }));
    console.log(columnVisibility);
  };

  useEffect(() => {
    const storedColumnVisibility = localStorage.getItem("columnVisibility");
    if (!!storedColumnVisibility) {
      console.log(storedColumnVisibility);
      setColumnVisibility(JSON.parse(storedColumnVisibility));
    }
  }, []);

  const updateLocalStorage = (state) => {
    localStorage.setItem("columnVisibility", JSON.stringify(state));
  };
  useEffect(() => {
    updateLocalStorage(columnVisibility);
  }, [columnVisibility]);
  const onFilterChange = (index, column, operator, value) => {
    console.log("onFilterChange", index, column, operator, value);

    let newValue = "";
    if (value !== undefined && value !== null) {
      newValue = value.trim();
    }
    if (newFilters.length === index) {
      setNewFilters((prevFilters) => [
        ...prevFilters,
        { column, operator, value: newValue },
      ]);
    } else {
      setNewFilters((prevFilters) => {
        const newFilters = [...prevFilters];
        // Modify the filter at the given index
        newFilters[index] = { column, operator, value: newValue };
        return newFilters;
      });
    }
  };
  return (
    <AdminLayout>
      <div className="flex flex-col h-full">
        <div className="flex justify-between mb-4">
          <div>
            <h1 className="text-2xl">Souscriptions</h1>

            <h2 className="text-sm  text-gray-700 dark:text-gray-300 mb-4">
              {data && data.data.length + " souscriptions"}
            </h2>
          </div>
          <div className="flex gap-x-4">
            <button
              onClick={() => refetch()}
              className={` inline-flex max-h-[40px] items-center py-2.5 px-5 font-medium text-gray-900 focus:outline-none  bg-white  rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 transition-all`}
            >
              {!isFetching ? <HiRefresh /> : <HiRefresh />}
            </button>
            {!isFetching && (
              <CSVLink
                separator=";"
                filename={
                  "souscriptions_" +
                  new Date().toLocaleDateString("fr-BE") +
                  ".csv"
                }
                data={table.getFilteredRowModel().rows.map((row) => {
                  return {
                    email: row.getValue("email"),
                    firstname: row.getValue("firstname"),
                    lastname: row.getValue("lastname"),
                    address: row.getValue("address"),
                    zipcode: row.getValue("zipcode"),
                    city: row.getValue("city"),
                    country: row.getValue("country"),
                    shareholding_type: row.getValue("shareholding_type"),
                    shareholding_started_at: formatDateDDMMYYYY(
                      row.getValue("shareholding_started_at")
                    ),
                    subscription_started_at: formatDateDDMMYYYY(
                      row.getValue("subscription_started_at")
                    ),
                  };
                })}
                className=" inline-flex max-h-[40px] items-center py-2.5 px-5 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
              >
                <FileDownloadIcon /> CSV Mailjet
              </CSVLink>
            )}
            {!isFetching && (
              <CSVLink
                separator=";"
                filename={
                  "souscriptions_" +
                  new Date().toLocaleDateString("fr-BE") +
                  ".csv"
                }
                data={dataCSV}
                className=" inline-flex max-h-[40px] items-center py-2.5 px-5 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
              >
                <FileDownloadIcon /> CSV
              </CSVLink>
            )}
          </div>
        </div>

        <div className="w-full bg-white dark:bg-gray-900 p-4 rounded-lg shadow-lg ">
          <div className="flex justify-between mb-4">
            <div className="flex">
              <DebouncedInput
                defaultValue={globalFilter ? globalFilter : ""}
                onChange={(value) => {
                  handleChangeGlobalFilter(value);
                }}
                className="p-2 font-lg shadow  border-block text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 z-30"
                placeholder="Rechercher partout...  "
              />
              {/* {auth.user.profile.email} */}
              <button
                className={` inline-flex max-h-[40px] items-center py-2.5 px-2.5 pl-5 font-medium text-gray-900 focus:outline-none  bg-white  rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 transition-all z-20 -ml-4 relative`}
                onClick={() => {
                  if (filters.length > 0) {
                    console.log("set filters", filters);

                    setNewFilters(filters);
                  }
                  setIsFilterModalOpen(true);
                }}
              >
                <HiPlus size={18} />
                {getFiltersCount() > 0 && (
                  <div
                    title="Filtres"
                    className="absolute -top-1 -right-1 bg-red-700 text-xs h-4 w-4 rounded-full text-white"
                  >
                    {getFiltersCount()}
                  </div>
                )}
              </button>
              {/* <button
                onClick={() => {
                  console.log(stringifiedFilters());
                }}
              >
                Log
              </button> */}
              {/* <form
                className="flex items-center gap-x-2 mx-4"
                onSubmit={filterForm.handleSubmit}
              >
                <input
                  type="checkbox"
                  id="subscriptionEndedAtIsNull"
                  name="subscriptionEndedAtIsNull"
                  // defaultChecked={filterForm.values.subscriptionEndedAtIsNull}
                  onChange={(e) => {
                    setIsFetchingAllUsers(e.target.checked);
                    refetch();
                  }}
                  defaultChecked={isFetchingAllUsers}
                />
                <label
                  htmlFor="resilies"
                  className="font-normal"
                  style={{ fontSize: `${tableFontSize * 0.75}em` }}
                >
                  {" "}
                  Afficher tous les utilisateurs (actifs et inactifs)
                </label>
              </form> */}
            </div>
            <div className="flex items-center">
              <div className="flex">
                <div>
                  <button className="p-1" onClick={() => reduceTableFontSize()}>
                    <TbLetterA size={14} />
                  </button>
                  <button
                    className="p-1"
                    onClick={() => increaseTableFontSize()}
                  >
                    <TbLetterA size={18} />
                  </button>
                </div>
              </div>
              <Popover.Root>
                <Popover.Trigger>
                  <div className="p-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-columns"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      strokeWidth="2"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <line x1="4" y1="6" x2="9.5" y2="6"></line>
                      <line x1="4" y1="10" x2="9.5" y2="10"></line>
                      <line x1="4" y1="14" x2="9.5" y2="14"></line>
                      <line x1="4" y1="18" x2="9.5" y2="18"></line>
                      <line x1="14.5" y1="6" x2="20" y2="6"></line>
                      <line x1="14.5" y1="10" x2="20" y2="10"></line>
                      <line x1="14.5" y1="14" x2="20" y2="14"></line>
                      <line x1="14.5" y1="18" x2="20" y2="18"></line>
                    </svg>
                  </div>
                </Popover.Trigger>
                <Popover.Anchor />
                <Popover.Portal>
                  <Popover.Content className="bg-gray-50 border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-6 shadow-xl">
                    <div className="grid rounded grid-cols-4">
                      {table.getAllLeafColumns().map((column) => {
                        if (column.id !== "actions2") {
                          return (
                            <div key={column.id} className="px-1">
                              <label>
                                <input
                                  {...{
                                    type: "checkbox",
                                    checked: column.getIsVisible(),
                                    onChange:
                                      column.getToggleVisibilityHandler(),
                                  }}
                                  value={column.id}
                                  className="capitalize"
                                  onClick={(e) => {
                                    handleCheckboxChange(e);
                                  }}
                                />
                                {getColumnLabel(column.id)}
                              </label>
                            </div>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </div>
                    <Popover.Close />
                    <Popover.Arrow className="fill-slate-500" />
                  </Popover.Content>
                </Popover.Portal>
              </Popover.Root>
            </div>
          </div>

          {isFetching || isLoading ? (
            <>
              <div className="w-full flex justify-center items-center">
                <PuffLoader color="rgb(29, 78, 216)" className="bg-Blu" />
              </div>
            </>
          ) : (
            <div>
              {/* <button
                onClick={() => {
                  // console.log(data);
                  // console.log(data.length > 0);
                  // console.log(auth);
                  console.log(generateQueryObject());
                }}
              >
                log{" "}
              </button> */}
              {/* <div>Filtres actifs</div>
              <div className="flex">
                <button className="inline-flex max-h-[40px] min-h-[40px] max-w-[40px] min-w-[40px] py-2.5 px-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-full border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 justify-center items-center">
                  <PlusIcon />
                </button>
                <div className="border rounded-md min-h-[40px] flex-1"></div>
              </div> */}

              {!!data && (
                <div>
                  <div
                    className="max-w-[93vw] min-h-[70vh] overflow-x-auto"
                    id="table"
                  >
                    <table
                      className="x-2 mb-4"
                      {...{
                        style: {
                          width: table.getCenterTotalSize(),
                        },
                      }}
                    >
                      <thead
                        className="text-left uppercase text-gray-700  bg-gray-50 dark:bg-gray-700 dark:text-gray-400 "
                        style={{ fontSize: `${tableFontSize * 0.75}em` }}
                      >
                        <DndProvider backend={HTML5Backend}>
                          {table.getHeaderGroups().map((headerGroup) => (
                            <tr key={headerGroup.id}>
                              {headerGroup.headers.map((header) => (
                                <DraggableColumnHeader
                                  key={header.id}
                                  header={header}
                                  table={table}
                                  columnResizeMode={columnResizeMode}
                                />
                              ))}
                            </tr>
                          ))}
                        </DndProvider>
                      </thead>
                      <thead>
                        {table.getFooterGroups().map((footerGroup) => (
                          <tr
                            key={footerGroup.id}
                            className="text-left uppercase text-gray-700  bg-gray-50 dark:bg-gray-800 dark:text-gray-400 "
                            style={{ fontSize: `${tableFontSize * 0.75}em` }}
                          >
                            {footerGroup.headers.map((header) => (
                              <th key={header.id} className="py-2 pr-2">
                                {header.isPlaceholder
                                  ? null
                                  : flexRender(
                                      header.column.columnDef.footer,
                                      header.getContext()
                                    )}
                                {header.column.getCanFilter() ? (
                                  <div>
                                    <TableFilter
                                      column={header.column}
                                      table={table}
                                    />
                                  </div>
                                ) : null}
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody>
                        {table.getRowModel().rows.map((row) => (
                          <tr
                            key={row.id}
                            id={row.index}
                            className="h-12 rounded-lg    even:bg-gray-100 dark:even:bg-gray-800 group"
                            style={{ fontSize: `${tableFontSize * 0.75}em` }}
                          >
                            {row.getVisibleCells().map((cell) => (
                              <td
                                key={cell.id}
                                className=" first:pl-2 last:pr-2  font-normal"
                                {...{
                                  style: {
                                    width: cell.column.getSize(),
                                  },
                                }}
                              >
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext()
                                )}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                      {/* <tfoot>
                        {table.getFooterGroups().map((footerGroup) => (
                          <tr key={footerGroup.id}>
                            {footerGroup.headers.map((header) => (
                              <th key={header.id}>
                                {header.isPlaceholder
                                  ? null
                                  : flexRender(
                                      header.column.columnDef.footer,
                                      header.getContext()
                                    )}
                                {header.column.getCanFilter() ? (
                                  <div>
                                    Hoho
                                    <TableFilter
                                      column={header.column}
                                      table={table}
                                    />
                                  </div>
                                ) : null}
                              </th>
                            ))}
                          </tr>
                        ))}
                      </tfoot> */}
                    </table>
                  </div>
                  <div className="flex justify-between">
                    <div>
                      <select
                        value={table.getState().pagination.pageSize}
                        className="flex justify-center items-center p-1.5  mr-1 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 h-8 py-1"
                        onChange={(e) => {
                          table.setPageSize(Number(e.target.value));
                        }}
                      >
                        {[10, 20, 30, 40, 50].map((pageSize) => (
                          <option key={pageSize} value={pageSize}>
                            Afficher {pageSize}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="flex gap-x-2">
                      <span className="flex items-center gap-1">
                        <div>Page</div>
                        <strong>
                          {table.getState().pagination.pageIndex + 1} sur{" "}
                          {table.getPageCount()}
                        </strong>
                      </span>
                      <span className="flex items-center gap-1">
                        | Aller à la page:
                        <input
                          type="number"
                          defaultValue={
                            table.getState().pagination.pageIndex + 1
                          }
                          onChange={(e) => {
                            const page = e.target.value
                              ? Number(e.target.value) - 1
                              : 0;
                            table.setPageIndex(page);
                          }}
                          className="flex justify-center items-center p-1.5  mr-1 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 w-16"
                        />
                      </span>
                    </div>
                    <div className="flex gap-x-1">
                      <button
                        className=" flex justify-center h-8 w-8 items-center p-1.5  mr-1 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                        onClick={() => table.setPageIndex(0)}
                        disabled={!table.getCanPreviousPage()}
                      >
                        {"<<"}
                      </button>
                      <button
                        className=" h-8 w-8 flex justify-center items-center p-1.5  mr-1 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                        onClick={() => table.previousPage()}
                        disabled={!table.getCanPreviousPage()}
                      >
                        {"<"}
                      </button>
                      <button
                        className=" flex justify-center h-8 w-8 items-center p-1.5  mr-1 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                        onClick={() => table.nextPage()}
                        disabled={!table.getCanNextPage()}
                      >
                        {">"}
                      </button>
                      <button
                        className=" flex justify-center h-8 w-8 items-center p-1.5  mr-1 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                        onClick={() =>
                          table.setPageIndex(table.getPageCount() - 1)
                        }
                        disabled={!table.getCanNextPage()}
                      >
                        {">>"}
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      {selectedSubscription !== null && (
        <ReactModal
          isOpen={isModalOpen}
          contentLabel="onRequestClose Example"
          onRequestClose={() => closeForm()}
          className="max-w-3xl w-full max-h-[80vh] text-black dark:text-white  bg-white dark:bg-gray-900 border border-gray-200 rounded-lg shadow-md   dark:border-gray-700  relative "
          overlayClassName={
            "h-screen w-screen bg-gray-900 bg-opacity-80 top-0 left-0 absolute  flex justify-center items-center shadow-lg backdrop-blur-sm overflow-hidden"
          }
        >
          <button
            onClick={() => setIsFormLocked(!isFormLocked)}
            className=" bg-white border border-gray-200 shadow-md absolute top-0 right-0 h-6 w-6 dark:bg-gray-800 dark:border-gray-700 mr-2 mt-2 flex items-center justify-center rounded"
          >
            {isFormLocked ? <LockIcon /> : <UnlockIcon />}
          </button>
          <div className="flex my-4">
            <div className="flex-1 text-xl font-semibold mb-2 px-4">
              Souscription #{selectedSubscription.id}
            </div>
          </div>
          <EditSubscriptionForm
            close={closeForm}
            edit={editSubscriptionMutation}
            activateAccount={activateAccountMutation}
            desactivateAccount={desactivateAccountMutation}
            reactivateAccount={reactivateAccountMutation}
            selectedSubscription={selectedSubscription}
            isFormLocked={isFormLocked}
          />
        </ReactModal>
      )}

      {isAddModalOpen && (
        <div
          className="h-screen w-screen bg-gray-900 bg-opacity-80 top-0 left-0 absolute  flex justify-center items-center shadow-lg backdrop-blur-sm overflow-hidden"
          onClick={(e) => {
            e.preventDefault();
            closeForm();
          }}
        >
          <div
            className="max-w-3xl w-full max-h-[80vh]   bg-white dark:bg-gray-900 border border-gray-200 rounded-lg shadow-md   dark:border-gray-700  relative"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <button
              onClick={() => setIsFormLocked(!isFormLocked)}
              className=" bg-white border border-gray-200 shadow-md absolute top-0 right-0 h-6 w-6 dark:bg-gray-800 dark:border-gray-700 mr-2 mt-2 flex items-center justify-center rounded"
            >
              {isFormLocked ? <LockIcon /> : <UnlockIcon />}
            </button>
            <div className="flex my-4">
              <div className="flex-1 text-xl font-semibold mb-2 px-4">
                {isModalOpen
                  ? `Souscription #${selectedSubscription.id}`
                  : "Nouvelle souscription"}
              </div>
            </div>
            {isModalOpen && selectedSubscription !== null && (
              <>
                <EditSubscriptionForm
                  close={closeForm}
                  edit={editSubscriptionMutation}
                  selectedSubscription={selectedSubscription}
                  isFormLocked={isFormLocked}
                />
              </>
            )}
            {isAddModalOpen !== null && (
              <>
                <AddSubscriptionForm
                  close={closeAddForm}
                  mutation={addSubscriptionMutation}
                />
              </>
            )}
          </div>
        </div>
      )}

      {!!selectedSubscription && (
        <AlertDialog.Root open={isDeleteAlertOpen}>
          <AlertDialog.Portal>
            <AlertDialog.Overlay
              className="bg-gray-900 bg-opacity-80 h-screen w-screen absolute top-0 left-0 flex justify-center items-center"
              onClick={(e) => e.stopPropagation()}
            />
            <AlertDialog.Content
              onClick={(e) => e.stopPropagation()}
              className="bg-white rounded-lg shadow dark:bg-gray-700 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-4 max-w-md w-full"
            >
              <AlertDialog.Title className="font-bold text-gray-900 dark:text-white mb-4">
                Êtes-vous sûr(e) ?
              </AlertDialog.Title>
              <AlertDialog.Description className="text-gray-700 dark:text-gray-200 mb-4">
                Voulez-vous vraiment supprimer la souscription de{" "}
                {selectedSubscription
                  ? selectedSubscription.firstname +
                    " " +
                    selectedSubscription.lastname
                  : "NON DÉFINI"}{" "}
                ?
              </AlertDialog.Description>
              <div className="flex gap-x-2 justify-end">
                <AlertDialog.Cancel
                  onClick={() => {
                    setSelectedSubscription(null);
                    setIsDeleteAlertOpen(false);
                  }}
                  className="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                >
                  Ne pas supprimer
                </AlertDialog.Cancel>
                <AlertDialog.Action asChild>
                  <button
                    onClick={(e) => {
                      deleteSubscriptionMutation.mutate(
                        selectedSubscription.id
                      );
                    }}
                    className="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-red-900 focus:outline-none bg-red-300 rounded-lg border border-red-400 hover:bg-red-100 hover:text-red-700 focus:z-10 focus:ring-4 focus:ring-red-200 dark:focus:ring-red-700 dark:bg-red-800 dark:text-red-400 dark:border-red-600 dark:hover:text-white dark:hover:bg-gray-700"
                  >
                    Supprimer
                  </button>
                </AlertDialog.Action>
              </div>
            </AlertDialog.Content>
          </AlertDialog.Portal>
        </AlertDialog.Root>
      )}
      {!!selectedSubscription && (
        <AlertDialog.Root open={isActivateAccountAlertOpen}>
          <AlertDialog.Portal>
            <AlertDialog.Overlay
              className="bg-gray-900 bg-opacity-80 h-screen w-screen absolute top-0 left-0 flex justify-center items-center"
              onClick={(e) => e.stopPropagation()}
            />
            <AlertDialog.Content
              onClick={(e) => e.stopPropagation()}
              className="bg-white rounded-lg shadow dark:bg-gray-700 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-4 max-w-md w-full"
            >
              <AlertDialog.Title className="font-bold text-gray-900 dark:text-white mb-4">
                Activation du compte de {selectedSubscription.firstname}{" "}
                {selectedSubscription.lastname}
              </AlertDialog.Title>
              <AlertDialog.Description className="text-gray-700 dark:text-gray-200 mb-4">
                <Label.Root
                  className="text-[15px] font-medium leading-[35px] text-black block"
                  htmlFor="password"
                >
                  Mot de passe
                </Label.Root>

                <input
                  className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500  mb-4"
                  type="password"
                  id="password"
                />
                <Label.Root
                  className="text-[15px] font-medium leading-[35px] text-black block"
                  htmlFor="confirm_password"
                >
                  Confimer mot de passe
                </Label.Root>

                <input
                  className=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500  mb-4"
                  type="password"
                  id="confirm_password"
                />
              </AlertDialog.Description>
              <div className="flex gap-x-2 justify-end">
                <AlertDialog.Cancel
                  onClick={() => {
                    setSelectedSubscription(null);
                    setIsActivateAccountAlertOpen(false);
                  }}
                  className="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                >
                  Annuler
                </AlertDialog.Cancel>
                <AlertDialog.Action asChild>
                  <button
                    onClick={(e) => {
                      deleteSubscriptionMutation.mutate(
                        selectedSubscription.id
                      );
                    }}
                    className="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-blue-900 focus:outline-none bg-blue-300 rounded-lg border border-blue-400 hover:bg-blue-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-red-200 dark:focus:ring-red-700 dark:bg-red-800 dark:text-red-400 dark:border-red-600 dark:hover:text-white dark:hover:bg-gray-700"
                  >
                    Activer le compte
                  </button>
                </AlertDialog.Action>
              </div>
            </AlertDialog.Content>
          </AlertDialog.Portal>
        </AlertDialog.Root>
      )}

      <ReactModal
        isOpen={isFilterModalOpen}
        contentLabel="onRequestClose Example"
        onRequestClose={() => setIsFilterModalOpen(false)}
        className="max-w-3xl w-full max-h-[80vh] text-black dark:text-white  bg-white dark:bg-gray-900 border border-gray-200 rounded-lg shadow-md   dark:border-gray-700  relative "
        overlayClassName={
          "h-screen w-screen bg-gray-900 bg-opacity-80 top-0 left-0 absolute  flex justify-center items-center shadow-lg backdrop-blur-sm overflow-hidden"
        }
      >
        <FilterForm
          onChange={onFilterChange}
          filters={newFilters}
          addParam={addParam}
          removeParam={removeParam}
          applyFunction={applyFilters}
          cancelFunction={cancelFilters}
          setGlobalOperator={setGlobalOperator}
          globalOperator={globalOperator}
          resetFilters={resetFilters}
        />
      </ReactModal>
      {/* <ReactQueryDevtools /> */}
    </AdminLayout>
  );
};

export default SubscriptionsPage2;
